// React
import React from "react";
import PropTypes from "prop-types";
// Framework
import { classnames } from "ui/classnames";
import { makeStyles, CardContent } from "ui/components";
import { Section } from "ui/section";

////////////////////////////////////////////////////
/// Styles
////////////////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    padding: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
  },
  dense: {
    padding: theme.spacing(2),
    "&:last-child": {
      paddingBottom: theme.spacing(2),
    },
  },
  gutter: {
    padding: theme.spacing(5),
    "&:last-child": {
      paddingBottom: theme.spacing(5),
    },
  },
  disableGutter: {
    padding: 0,
    "&:last-child": {
      paddingBottom: 0,
    },
  },
  scrollable: {
    overflow: "auto",
  },
  scrollableX: {
    overflowX: "auto",
  },
  scrollableY: {
    overflowY: "auto",
  },
  justifyContent: ({ justifyContent }) => ({
    justifyContent,
  }),
}));

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const TileContent = ({
  context = "content",
  value,
  dense,
  gutter,
  disableGutter,
  justifyContent,
  scrollable,
  scrollableX,
  scrollableY,
  className,
  children,
}) => {
  // Styles
  const classes = useStyles({ justifyContent });
  // Render
  return (
    <Section context={context} value={value}>
      <CardContent
        className={classnames(classes.root, className, classes.justifyContent, {
          [classes.dense]: dense,
          [classes.gutter]: gutter,
          [classes.disableGutter]: disableGutter,
          [classes.scrollable]: scrollable,
          [classes.scrollableX]: scrollableX,
          [classes.scrollableY]: scrollableY,
        })}
      >
        {children}
      </CardContent>
    </Section>
  );
};

TileContent.propTypes = {
  context: PropTypes.string,
  value: PropTypes.string,
  dense: PropTypes.bool,
  gutter: PropTypes.bool,
  scrollable: PropTypes.bool,
  scrollableX: PropTypes.bool,
  scrollableY: PropTypes.bool,
  disableGutter: PropTypes.bool,
  justifyContent: PropTypes.oneOf([
    "flex-start",
    "center",
    "flex-end",
    "space-between",
    "space-around",
    "space-evenly",
  ]),
  className: PropTypes.string,
  children: PropTypes.any,
};

export default TileContent;
