// React
import React, { useMemo } from "react";
// Helpers
import { startsWith, find } from "@mefisto/utils";
// Framework
import { useUser, useTranslate } from "@mefisto/web";
// Icons
import {
  PremiumMacrosIcon,
  PremiumMealPlanIcon,
  PremiumVideoIcon,
  PremiumWorkoutIcon,
  PremiumCourseIcon,
} from "icons";

export const usePremiumAdvantages = () => {
  // Framework
  const { t } = useTranslate();
  // Render
  return useMemo(() => {
    return [
      {
        label: t(
          "billing:viewModel.subscription.premiumAdvantages.macros.label"
        ),
        icon: <PremiumMacrosIcon />,
        color: "#c55127",
      },
      {
        label: t(
          "billing:viewModel.subscription.premiumAdvantages.mealPlan.label"
        ),
        icon: <PremiumMealPlanIcon />,
        color: "#ef612e",
      },
      {
        label: t(
          "billing:viewModel.subscription.premiumAdvantages.video.label"
        ),
        icon: <PremiumVideoIcon />,
        color: "#f99f1b",
      },
      {
        label: t(
          "billing:viewModel.subscription.premiumAdvantages.workout.label"
        ),
        icon: <PremiumWorkoutIcon />,
        color: "#f8c013",
      },
      {
        label: t(
          "billing:viewModel.subscription.premiumAdvantages.course.label"
        ),
        icon: <PremiumCourseIcon />,
        color: "#0e76bc",
      },
    ];
  }, [t]);
};

export const usePrices = (subscriptionOffer) => {
  // Framework
  const { t } = useTranslate();
  // Render
  return useMemo(() => {
    const offer = (code) =>
      find(subscriptionOffer?.products, ({ price }) => price?.code === code);
    return [
      // {
      //   title: t("billing:viewModel.subscription.prices.monthly.title"),
      //   priceTitle: t(
      //     "billing:viewModel.subscription.prices.monthly.price.title"
      //   ),
      //   priceSubtitle: t(
      //     "billing:viewModel.subscription.prices.monthly.price.subtitle"
      //   ),
      //   color: "#0e76bc",
      //   ...offer("PREMIUM_MONTHLY"),
      // },
      // {
      //   title: t("billing:viewModel.subscription.prices.annual.title"),
      //   priceTitle: t(
      //     "billing:viewModel.subscription.prices.annual.price.title"
      //   ),
      //   priceSubtitle: t(
      //     "billing:viewModel.subscription.prices.annual.price.subtitle"
      //   ),
      //   color: "#f8c013",
      //   recommended: true,
      //   ...offer("PREMIUM_ANUAL"),
      // },
      {
        title: t("billing:viewModel.subscription.prices.annual.title"),
        priceTitle: t(
          "billing:viewModel.subscription.prices.annualNext.price.title"
        ),
        priceSubtitle: t(
          "billing:viewModel.subscription.prices.annualNext.price.subtitle"
        ),
        color: "#f8c013",
        recommended: true,
        ...offer("PREMIUM_ANUAL"),
      },
    ];
  }, [t, subscriptionOffer]);
};

export const useSubscribed = () => {
  // Framework
  const {
    user: {
      data: { metadata },
    },
  } = useUser();
  // Memo
  const subscribed = useMemo(() => {
    return startsWith(metadata?.product, "PREMIUM");
  }, [metadata]);
  // Render
  return { subscribed };
};
