// React
import React from "react";
// Framework
import { usePortal, forwardType, External, Externals } from "@mefisto/web";
// Components
import AppIcon from "./images/app-icon.png";
import CaribenoIntroPoster from "./images/caribeno-intro-poster.png";
import CaribenoInterviewsPoster from "./images/caribeno-interviews-poster.png";
import OnboardingBackground from "./images/onboarding-background.png";
import OnboardingBackgroundLarge from "./images/onboarding-background-large.png";
import SplashScreen from "./images/splash-screen.png";
import LogoAnimationMov from "./videos/logo-animation.mov";
import LogoAnimationWebm from "./videos/logo-animation.webm";
import Introduction from "./videos/introduction.mp4";
import ShowcaseMov1x from "./videos/showcase.mov";
import ShowcaseWebm1x from "./videos/showcase.webm";
import ShowcasePoster from "./images/showcase-poster.png";
import SpinnerBeach from "./images/spinner-beach.json";
import Bonus1Mov1x from "./videos/bonus1.mov";
import Bonus1Webm1x from "./videos/bonus1.webm";
import Bonus1Poster from "./images/bonus1.png";
import Bonus2Mov1x from "./videos/bonus2.mov";
import Bonus2Webm1x from "./videos/bonus2.webm";
import Bonus2Poster from "./images/bonus2.png";
import Bonus3Mov1x from "./videos/bonus3.mov";
import Bonus3Webm1x from "./videos/bonus3.webm";
import Bonus3Poster from "./images/bonus3.png";
import Step1Mov1x from "./videos/step1.mov";
import Step1Webm1x from "./videos/step1.webm";
import Step1Poster from "./images/step1.png";
import Step2Mov1x from "./videos/step2.mov";
import Step2Webm1x from "./videos/step2.webm";
import Step2Poster from "./images/step2.png";
import Step3Mov1x from "./videos/step3.mov";
import Step3Webm1x from "./videos/step3.webm";
import Step3Poster from "./images/step3.png";
import Testimonial1 from "./images/testimonial-1.jpeg";
import Testimonial2 from "./images/testimonial-2.jpeg";
import Testimonial3 from "./images/testimonial-3.jpeg";
import Results from "./videos/results.mp4";
import ResultsPoster from "./images/results-poster.jpg";

export default forwardType(Externals, () => {
  // Framework
  const { config } = usePortal();
  // prettier-ignore
  return (
    <Externals>
      {/* Images */}
      <External name="APP_ICON" path={AppIcon} />
      <External name="CARIBENO_INTRO_POSTER" path={CaribenoIntroPoster} />
      <External name="CARIBENO_INTRO_VIDEO" path={config.get("appsPresentationAssetsIntroVideo")} />
      <External name="CARIBENO_INTERVIEWS_POSTER" path={CaribenoInterviewsPoster} />
      <External name="CARIBENO_INTERVIEWS_VIDEO" path={config.get("appsPresentationAssetsInterviewsVideo")} />
      <External name="ONBOARDING_BACKGROUND" path={OnboardingBackground} />
      <External name="ONBOARDING_BACKGROUND_LARGE" path={OnboardingBackgroundLarge} />
      <External name="SPINNER_BEACH" path={SpinnerBeach} />
      <External name="SPLASH_SCREEN" path={SplashScreen} />
      <External name="STEP1_POSTER" path={Step1Poster} />
      <External name="STEP2_POSTER" path={Step2Poster} />
      <External name="STEP3_POSTER" path={Step3Poster} />
      <External name="BONUS1_POSTER" path={Bonus1Poster} />
      <External name="BONUS2_POSTER" path={Bonus2Poster} />
      <External name="BONUS3_POSTER" path={Bonus3Poster} />
      <External name="SHOWCASE_POSTER" path={ShowcasePoster} />
      {/* Videos */}
      <External name="LOGO_ANIMATION_MOV" path={LogoAnimationMov} />
      <External name="LOGO_ANIMATION_WEBM" path={LogoAnimationWebm} />
      <External name="INTRODUCTION" path={Introduction} />
      <External
        name="SHOWCASE"
        path={[
          { path: ShowcaseMov1x, extension: "mov", devicePixelRatio: 1 },
          { path: ShowcaseMov1x, extension: "mov", devicePixelRatio: 2 },
          { path: ShowcaseWebm1x, extension: "webm", devicePixelRatio: 1 },
          { path: ShowcaseWebm1x, extension: "webm", devicePixelRatio: 2 },
        ]}
      />
      <External
        name="STEP1"
        path={[
          { path: Step1Mov1x, extension: "mov", devicePixelRatio: 1 },
          { path: Step1Mov1x, extension: "mov", devicePixelRatio: 2 },
          { path: Step1Webm1x, extension: "webm", devicePixelRatio: 1 },
          { path: Step1Webm1x, extension: "webm", devicePixelRatio: 2 },
        ]}
      />
      <External
        name="STEP2"
        path={[
          { path: Step2Mov1x, extension: "mov", devicePixelRatio: 1 },
          { path: Step2Mov1x, extension: "mov", devicePixelRatio: 2 },
          { path: Step2Webm1x, extension: "webm", devicePixelRatio: 1 },
          { path: Step2Webm1x, extension: "webm", devicePixelRatio: 2 },
        ]}
      />
      <External
        name="STEP3"
        path={[
          { path: Step3Mov1x, extension: "mov", devicePixelRatio: 1 },
          { path: Step3Mov1x, extension: "mov", devicePixelRatio: 2 },
          { path: Step3Webm1x, extension: "webm", devicePixelRatio: 1 },
          { path: Step3Webm1x, extension: "webm", devicePixelRatio: 2 },
        ]}
      />
      <External
        name="BONUS1"
        path={[
          { path: Bonus1Mov1x, extension: "mov", devicePixelRatio: 1 },
          { path: Bonus1Mov1x, extension: "mov", devicePixelRatio: 2 },
          { path: Bonus1Webm1x, extension: "webm", devicePixelRatio: 1 },
          { path: Bonus1Webm1x, extension: "webm", devicePixelRatio: 2 },
        ]}
      />
      <External
        name="BONUS2"
        path={[
          { path: Bonus2Mov1x, extension: "mov", devicePixelRatio: 1 },
          { path: Bonus2Mov1x, extension: "mov", devicePixelRatio: 2 },
          { path: Bonus2Webm1x, extension: "webm", devicePixelRatio: 1 },
          { path: Bonus2Webm1x, extension: "webm", devicePixelRatio: 2 },
        ]}
      />
      <External
        name="BONUS3"
        path={[
          { path: Bonus3Mov1x, extension: "mov", devicePixelRatio: 1 },
          { path: Bonus3Mov1x, extension: "mov", devicePixelRatio: 2 },
          { path: Bonus3Webm1x, extension: "webm", devicePixelRatio: 1 },
          { path: Bonus3Webm1x, extension: "webm", devicePixelRatio: 2 },
        ]}
      />
        <External name="TESTIMONIAL_1" path={Testimonial1} />
        <External name="TESTIMONIAL_2" path={Testimonial2} />
        <External name="TESTIMONIAL_3" path={Testimonial3} />
        <External name="RESULTS" path={Results} />
        <External name="RESULTS_POSTER" path={ResultsPoster} />
    </Externals>
  );
});
