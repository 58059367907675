// React
import React from "react";
// Framework
import { App, forwardType } from "@mefisto/web";
// Icon
import { PortalIcon } from "icons";
// Loader
import { Loader } from "loader";
// Plugins
import { paths as portalPaths, portal as permissions } from "@plugins/portal";
import { paths as billingPaths } from "@plugins/billing";

export default forwardType(App, () => (
  <App
    visible
    enabled
    name="PORTAL"
    type="USER"
    title="Portal"
    color="#00b3e3"
    priority={1}
    icon={<PortalIcon />}
    loader={<Loader />}
    navigation={{
      basename: "portal",
      routes: {
        default: "/dashboard",
      },
      paths: {
        // Billing
        ...billingPaths,
        billingCustomer: "/profile",
        // Portal
        ...portalPaths,
        dashboard: "/dashboard",
        onboarding: "/onboarding/:step",
        regenerate: "/regenerate/:step",
        // Education
        courses: "/education/courses",
        course: "/education/courses/:course/:lesson",
        // Nutrition
        mealPlan: "/nutrition/meal-plan",
        shoppingList: "/nutrition/shopping-list",
        // Training
        workoutPrograms: "/training/workout-programs",
        workoutProgram: "/training/workout-programs/:workoutProgram",
        workoutPlan: "/training/workout-plan",
        // Body
        menstruation: "/body/menstruation",
        hydration: "/body/hydration",
        weight: "/body/weight",
        // - Deprecated
        menu: "/menu",
      },
    }}
    resource={{
      source: "ui",
    }}
    permissions={[...permissions()]}
  />
));
