// React
import React from "react";
// Framework
import { App, forwardType } from "@mefisto/web";
// Icon
import { AdminIcon } from "icons";
// Loader
import { Loader } from "loader";
// Navigation
import { paths as billingPaths } from "@plugins/billing";
import { paths as portalPaths } from "@plugins/portal";
import { paths as supportPaths } from "@plugins/support";

export default forwardType(App, () => (
  <App
    enabled
    visible
    name="ADMIN"
    type="USER"
    title="Admin"
    color="#f35448"
    priority={0}
    icon={<AdminIcon />}
    loader={<Loader />}
    logo={{ title: "Admin" }}
    navigation={{
      basename: "admin",
      routes: {
        default: "/users",
      },
      paths: {
        ...billingPaths,
        ...portalPaths,
        ...supportPaths,
        dashboard: "/dashboard",
        workoutPrograms: "/training/workout-programs",
        workoutProgram: "/training/workout-programs/:workoutProgram",
        courses: "/education/courses",
        course: "/education/courses/:course",
        ingredients: "/nutrition/ingredients",
        recipes: "/nutrition/recipes",
      },
    }}
    resource={{
      source: "navigation",
    }}
  />
));
